<template>
  <div>
    <Integrate></Integrate>
  </div>
</template>

<script>
import Integrate from '@/components/Integrations/ListaIntegrations.vue'
  export default {
    components: {
      Integrate,
    },
  }
</script>

<style lang="scss" scoped>

</style>