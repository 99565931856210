<template>
  <div class="container">
    <BaseHeader title="Integrações" :navigation="navigation"> </BaseHeader>
    <div class="mb-4">
      <div class="grid-aplicativos">
        <div
          class="item"
        >
          <div class="header">
            <img
              src="@/assets/integrations/greenn/logo.png"
              alt="img"
              style="width: 30px; border-radius: 5px"
            />
            <p>Greenn</p>
          </div>
          <div class="text">
            Transforme seus compradores da Greenn em leads da {{ $store.getters["getNameSystem"] }}!
          </div>

          <div class="footer">
            <div @click="openModal('ModalIntegrationGreenn')">
              <div class="access">
                <p>Configurar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          class="item"
        >
          <div class="header">
            <img
              src="@/assets/integrations/typebot/logo.png"
              alt="img"
              style="width: 30px; border-radius: 5px"
            />
            <p>Typebot</p>
          </div>
          <div class="text">
            Crie conversas em conversões com chatbots integradas a {{ nameSystem }}.
          </div>

          <div class="footer">
            <div @click="openModal('ModalIntegrationTypebot')">
              <div class="access">
                <p>Configurar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          class="item"
        >
          <div class="header">
            <img
              src="@/assets/integrations/elementor/logo.jpg"
              alt="img"
              style="width: 30px; border-radius: 5px"
            />
            <p>Elementor</p>
          </div>
          <div class="text">
            Integre um formulário no Elementor com seu funil na {{ nameSystem }}.
          </div>

          <div class="footer">
            <div @click="openModal('ModalIntegrationElementor')">
              <div class="access">
                <p>Configurar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="displayPluga">
          <div class="header">
            <img src="@/assets/integrations/pluga/logo.webp" alt="logo"
            style="width: 30px; border-radius: 5px">
            <p>Pluga</p>
          </div>
          <div class="text">
            Automatize o fluxo de dados e potencialize suas vendas. 
          </div>
          <div class="footer">
            <div @click="openModal('ModalIntegrationPluga')">
              <div class="access">
                <p>Configurar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          class="item"
        >
          <div class="header">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#4ea934" viewBox="0 0 256 256"><path d="M178.16,176H111.32A48,48,0,1,1,25.6,139.19a8,8,0,0,1,12.8,9.61A31.69,31.69,0,0,0,32,168a32,32,0,0,0,64,0,8,8,0,0,1,8-8h74.16a16,16,0,1,1,0,16ZM64,184a16,16,0,0,0,14.08-23.61l35.77-58.14a8,8,0,0,0-2.62-11,32,32,0,1,1,46.1-40.06A8,8,0,1,0,172,44.79a48,48,0,1,0-75.62,55.33L64.44,152c-.15,0-.29,0-.44,0a16,16,0,0,0,0,32Zm128-64a48.18,48.18,0,0,0-18,3.49L142.08,71.6A16,16,0,1,0,128,80l.44,0,35.78,58.15a8,8,0,0,0,11,2.61A32,32,0,1,1,192,200a8,8,0,0,0,0,16,48,48,0,0,0,0-96Z"></path></svg>
            <p>Webhook</p>
          </div>
          <div class="text">
            Documentação completa sobre APIs públicas Webhook da {{ $store.getters["getNameSystem"] }}
          </div>

          <div class="footer">
            <div @click="redirectDocsApi()">
              <div class="access">
                <p>Ver documentação</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalIntegrationTypebot/>
    <ModalIntegrationElementor/>
    <ModalIntegrationGreenn/>
    <ModalIntegrationPluga/>

  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";

import ModalIntegrationTypebot from "./ModalIntegrationTypebot.vue";
import ModalIntegrationElementor from "./ModalIntegrationElementor.vue";
import ModalIntegrationGreenn from "./ModalIntegrationGreenn.vue";
import ModalIntegrationPluga from "./ModalIntegrationPluga.vue";
//

import GlobalSettingsService from "@/services/resources/GlobalSettingsService";
const serviceGlobalSettings = GlobalSettingsService.build();

export default {
  name: "ListaMidias",
  components: {
    BaseHeader,
    ModalIntegrationTypebot,
    ModalIntegrationElementor,
    ModalIntegrationGreenn,
    ModalIntegrationPluga
  },
  data() {
    return {
      navigation: [
        { link: "Aplicativos", to: "/dynamicRoute/apps" },
        { link: "Integrações", to: this.$route.fullPath },
      ],
      displayPluga: false
    };
  },
  computed:{
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
  },
  created(){
    this.canDisplayPlugaCard();
  },
  methods: {
    redirectDocsApi(){
      window.open("https://back.gdigital.com.br/api/docs", '_blank')
    },    
    openModal(data) {
      this.$bvModal.show(data);
    },
    async canDisplayPlugaCard() {
      const setting = await this.getGlobalSetting('pluga-integration');
      this.displayPluga =  !setting ? false : setting === 'true';
    },
    getGlobalSetting(setting) {
      return new Promise((resolve, reject) => {
        serviceGlobalSettings
        .read({id: setting})
        .then((resp) => {
          if(!resp.meta_value){
            resolve(false);
          }
          resolve(resp.meta_value);
        })
        .catch(() => {
          reject(false)
        });
      });
    }
  }

};
</script>

<style lang="scss" scoped>
.blocked {
  position: relative;
  background-color: var(--white-light);
  cursor: not-allowed;
  user-select: none;
  -webkit-user-drag: none;
  * {
    cursor: not-allowed !important;
    user-select: none;
    -webkit-user-drag: none;
  }
  &:hover::before {
    max-width: 150px;
    width: 150px;
    padding-right: 10px;
    color: white !important;
  }
  &::before {
    position: absolute;
    top: 15px;
    right: -10px;
    max-width: 35px;
    width: 35px;
    height: 35px;
    transition: all 0.5s;
    padding-left: 35px;
    background-color: var(--greenn);
    line-height: 1;
    color: #ffffff00 !important;
    white-space: pre;
    content: "Não incluso\A em seu plano";
    overflow: hidden;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="0 0 256 256"><path stroke="%23ffffff" stroke-width="5" d="M208,80H176V56a48,48,0,0,0-96,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM96,56a32,32,0,0,1,64,0V80H96ZM208,208H48V96H208V208Z"></path></svg>');
    background-position: 8px center;
    background-repeat: no-repeat;
    background-size: 20px;
    color: var(--greenn);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 5px 0 10px;
    font-size: 13px;
  }
  &::after {
    content: "";
    background-color: var(--greenn-dark);
    width: 10px;
    height: 10px;
    clip-path: polygon(0% 0%, 100% 0%, 0 100%);
    position: absolute;
    top: 50px;
    right: -10px;
  }
}
.hidden {
  position: relative;
  background: var(--white-light);
  cursor: not-allowed;
  user-select: none;
  -webkit-user-drag: none;
  * {
    cursor: not-allowed !important;
    user-select: none;
    -webkit-user-drag: none;
  }
  &::before {
    position: absolute;
    top: 15px;
    right: -10px;
    width: 100px !important;
    padding: 0 !important;
    height: 30px;
    background: var(--greenn);
    content: "Em breve!" !important;
    max-width: 100px !important;
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 5px 0 10px;
    font-size: 13px;
  }
  &::after {
    content: "";
    background-color: var(--greenn-dark);
    width: 10px;
    height: 10px;
    clip-path: polygon(0% 0%, 100% 0%, 0 100%);
    position: absolute;
    top: 45px;
    right: -10px;
  }
}
a {
  text-decoration: none;
}
.card-apps {
  overflow: hidden;
  display: grid;
  justify-content: center;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  padding: 45px;
  cursor: pointer;
  transition: all 0.2s;
  background: #f7f7f7;
  &:hover {
    background: #e7e7e7a2;
  }
}
.grid-aplicativos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  .item {
    width: 100%;
    border: var(--white-medium) solid 1px;
    border-radius: 10px;
    padding: 25px 20px;
    .footer {
      border-top: var(--white-medium) solid 1px;
      padding-top: 10px;
      cursor: pointer;
      .access {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-top: 10px;

        p {
          margin: 0;
          color: var(--gray05);
        }
      }
    }
    .text {
      color: var(--gray05);
      margin-bottom: 25px;
    }
    .header {
      display: flex;
      gap: 10px;
      margin-bottom: 15px;
      align-items: center;
      p {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 65px;
  }
}

</style>
