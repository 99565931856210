<template>
  <BaseModal
    name="ModalIntegrationGreenn"
    id="ModalIntegrationGreenn"
    idModal="ModalIntegrationGreenn"
    size="lg"
    title="Integração - Greenn"
    @shown="openModal()"
    @hidden="step = 0"
    :hideFooter="true"
  >
  <div class="content">
    <div class="content-step" :style="stepStyle[0] ? stepStyle[0] : ''">
      <label>1 - Acesse as configurações de um produto Greenn</label>
      <p>Em seu painel da Greenn, escolha o produto que deseja integrar e selecione a engrenagem para abrir as configurações do produto</p>
      <img src="@/assets/integrations/greenn/step1.png" alt="Primeiro passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[1] ? stepStyle[1] : ''">
      <label>2 - Selecione "Adicionar Integração"</label>
      <p>Já na tela de configurações do seu produto, na aba de "Integrações", clique no botão "Adicionar integração"</p>
      <img src="@/assets/integrations/greenn/step2.png" alt="Primeiro passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[2] ? stepStyle[2] : ''">
      <label>3 - Selecione a opção {{ $store.getters["getNameSystem"] }}</label>
      <img src="@/assets/integrations/greenn/step3.png" alt="Primeiro passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[3] ? stepStyle[3] : ''">
      <label>4 - Valide seu e-mail</label>
      <p>Siga as instruções no modal para validar sua identidade e garantir mais segurança para seu painel da {{ $store.getters["getNameSystem"] }}</p>
      <img src="@/assets/integrations/greenn/step4.png" alt="Primeiro passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[4] ? stepStyle[4] : ''">
      <label>5 - Selecione seu painel</label>
      <img src="@/assets/integrations/greenn/step5.png" alt="Primeiro passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[5] ? stepStyle[5] : ''">
      <label>6 - Configure quais eventos irão cadastrar o lead</label>
      <p>Ative os eventos que serão usados como gatilho para gerar novos leads. <br><b>Lacuna Vendas:</b> Eventos referentes aos produtos de valor único. <br> <b>Lacuna Assinatura:</b> Eventos referentes aos produtos de assinaturas, seja mensal, trimestral, semestral ou anual. <br> <b>Checkout Abandonado: </b>Caso queira visualizar os Leads que abandonaram seu checkout.</p>
      <img src="@/assets/integrations/greenn/step6.png" alt="Primeiro passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[6] ? stepStyle[6] : ''">
      <label>7 - Selecione um formulário para fazer a integração</label>
      <p>Todas as automações desse formulário também serão aplicadas ao lead. Isso permitirá que você adicione-o a CRMs e tags automaticamente</p>
      <img src="@/assets/integrations/greenn/step7.png" alt="Primeiro passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[7] ? stepStyle[7] : ''">
      <label>8 - Clique em adicionar e aproveite sua integração!</label>
      <img src="@/assets/integrations/greenn/step8.png" alt="Primeiro passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
  </div>
  <div class="footer-botton">
    <BaseButton variant="link-info" v-if="step" @click="step--">Voltar</BaseButton>
    <div v-else></div>
    <BaseButton variant="primary" @click="step++" v-if="step < 7">Avançar</BaseButton>
    <BaseButton variant="primary" @click="closeModal()" v-else>Finalizar</BaseButton>
  </div>
  </BaseModal>
</template>

<script>
import GlobalTokenService from "@/services/resources/GlobalTokenService";
const serviceGlobalToken = GlobalTokenService.build();

export default {
    data() {
      return {
        form: false,
        allForms:[],
        loadingForm:false,
        stop: false,
        globalToken:'',
        step: 0,
      }
    },
    computed: {
      returURL() {
        return this.form ? `https://back.gdigital.com.br/integration/elementor?token=${this.globalToken}&form_id=${this.form.id}` : ''
      },
      stepStyle() {
        let styles = []
        for (let i = 0; i < this.step + 1; i++) {
          styles.push(i === this.step ? 'transform: translateX(0%);' : 'transform: translateX(-120%);')
        }
        return styles
      }
    },
    methods: {
      getGlobalToken() {  
        serviceGlobalToken
          .search()
          .then((resp) => {
            this.globalToken = resp.token;
          });
      },
      openModal(){
        if (!this.globalToken) {
          this.getGlobalToken()
        }
      },
      closeModal() {
        this.$bvModal.hide("ModalIntegrationGreenn");
      },
    },
  }
</script>

<style lang="scss" scoped>
.footer-botton {
  display: flex;
  justify-content: space-between;
}
.step-picture {
  border-radius: 10px;
  width: calc(80% - 15px);
}
.content {
  position: relative;
  width: 100%;
  height: 60vh;
  max-height: 60vh;
  overflow: hidden;
  .content-step {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
    transform: translateX(120%);
    transition: .5s;
  }
  label {
    font-size: 16px !important;
  }
  p {
    padding-left: 15px;
  }
  img {
    margin-left: 15px;
  }
}
.color-arrow{
  stroke:var(--bluee);
  width: 20px;
}
.Elementor-button{
  display: flex;
  align-items: center;
  gap: 10px;
}
.select-input{
  width: 100%;
  display: grid;
  gap: 25px;
}
.input-url-integration{
  svg{
    color: var(--gray);
  }
}
.input-url-integration:hover{
  border-color: #ededf0 !important;  
}
.url-code {
  background-color: var(--white-light);
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  max-height: 200px;
  position: relative;
  color: var(--gray02);
  padding: 14px 15px;
  min-height: 50px;
  align-items: center;
  display: flex;
  code {
    width: 100%;
    color: var(--gray02);
    word-break: break-all;
  }
}
.ok{
  color: var(--greenn);
  background-color: var(--greenn-transparent);
  cursor: pointer;
  &:hover {
    background-color: var(--greenn2)
  }
  >svg {
    fill: var(--greenn);
  }
}
.not{
  color: var(--gray03);
  background-color: var(--white-dark);
  cursor: not-allowed;
  >svg {
    fill: var(--gray03);
  }
}
.copy-tag {
  height: 30px;
  font-size: 13px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;
  position: absolute;
  top: -30px;
  right: 0;
  transition: .3s;
}

</style>